<template>
  <div class="w-1200 mt-5 mb-5">
    <el-steps :active="1" simple>
      <el-step title="选择商品" icon="iconfont icon-gouwuche"></el-step>
      <el-step title="填写订单信息" icon="el-icon-edit"></el-step>
      <el-step title="提交订单" icon="el-icon-upload"></el-step>
    </el-steps>
    <el-table class="mt-2"
              ref="multipleTable" border stripe
              :data="cartList"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="cartSelectionChange">
      <el-table-column
        align="center"
        label="全选"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="图片" width="80" align="center">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            trigger="hover"
          >
            <img
              slot="reference"
              style="width: 50px; height: 50px"
              v-lazy="scope.row.slide_list.img"
            />
            <img
              style="width: 220px; height: 220px"
              :src="scope.row.slide_list.img"
              fit="cover"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="title"/>
      <el-table-column label="单价" prop="price" width="90" header-align="center" align="right">
        <template slot-scope="scope">
          <div>￥{{ scope.row.price }}</div>
        </template>
      </el-table-column>
      <el-table-column label="规格" prop="sku" width="130"/>
      <el-table-column label="数量" prop="title" width="100">
        <template slot-scope="scope">
          <div class="amount-warp">
            <input class="border" @input="cartGoodsAmountChange($event,scope.row)" v-model="scope.row.amount"/>
            <div class="quick-button">
              <i class="border el-icon-arrow-up" @click="addAmountCount(scope.row)"></i>
              <i class="border el-icon-arrow-down" @click="subtractAmountCount(scope.row)"></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="小计" width="90" header-align="center" align="right">
        <template slot-scope="scope">
          <div>￥{{ (scope.row.price * scope.row.amount).toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteCartGoods(scope.row)"/>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="bg-f1 mt-2 d-f justify-between cart-option">
      <div class="delete" @click="batchDeleteCartGoods">删除选中的商品</div>
      <div class="other-info d-f justify-between">
        <div class="common">已选择<span class="total-num text-main-color">{{selectGoodsList.length}}</span>件商品</div>
        <div class="common">总价(不含运费)：<span class="total-price text-main-color">￥{{cartTotalPrice}}</span></div>
        <el-button type="danger" @click="toEditOrder">去支付</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCartListUrl, changeCartGoodsNumUrl, deleteCartGoodsUrl } from '@/api/index.js'
import { debounce } from '@/utils/util.js'

export default {
  name: 'Cart',
  data () {
    return {
      cartList: [],
      selectGoodsList: [],
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      let token = window.localStorage.getItem('token')
      if(token == '' || token==null){
        this.$router.push('/login')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getCartList()
      setTimeout(()=>{
        this.changeLoading(loading)
      },300)
    },
    // 关闭loading弹窗
    changeLoading(loading){
      loading.close();
    },
    // 获取购物车列表
    async getCartList () {
      const { data: res } = await this.$http.get(getCartListUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.cartList = res.data.list
      this.cartList.forEach(item => {
        if (item.skuJson) {
          item.sku = []
          for (let i in item.skuJson) {
            item.sku.push(item.skuJson[i])
          }
          item.sku = item.sku.join('，')
        }
      })
    },
    // 购物车选择事件
    cartSelectionChange (val) {
      this.selectGoodsList = val
    },
    // 商品数量文本框改变事件
    cartGoodsAmountChange (e, orderInfo) {
      if (e.target.value <= 0) {
        orderInfo.amount = 1
        return this.$message.error('商品数量不能小于0')
      }
      this.changeCartGoodsNum(orderInfo.id, e.target.value)
    },
    // 商品数量加1
    addAmountCount (orderInfo) {
      ++orderInfo.amount
      this.changeCartGoodsNum(orderInfo.id, orderInfo.amount)
    },
    // 商品数量减1
    subtractAmountCount (orderInfo) {
      if (orderInfo.amount <= 1) {
        return this.$message.error('商品数量不能小于0')
      }
      --orderInfo.amount
      this.changeCartGoodsNum(orderInfo.id, orderInfo.amount)
    },
    // 防抖函数修改购物车商品数量
    changeCartGoodsNum: debounce(function(goodsId, count) {
      this.$http.put(changeCartGoodsNumUrl + goodsId, { nums: count }).then(res => {
        console.log(res)
      })
    }),
    // 单个删除购物车商品
    deleteCartGoods (orderInfo) {
      console.log(orderInfo)
      let httpObj = {
        ids: [orderInfo.id]
      }
      this.$confirm('此操作将移除购物车的商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post(deleteCartGoodsUrl, httpObj)
        if (res.code !== 200) {
          this.$message.error('删除商品失败' + res.msg)
          return false
        }
        this.$message.success('删除成功')
        this.initData()
        await this.$store.dispatch('changeCartNumActions')
      }).catch((e) => {
        console.log(e)
        this.$message.info('已取消删除')
      })
    },
    // 批量删除购物车商品
    batchDeleteCartGoods () {
      let httpObj = {
        ids: []
      }
      if (this.selectGoodsList.length > 0) {
        this.selectGoodsList.forEach(item => {
          httpObj.ids.push(item.id)
        })
      } else {
        return this.$message.error('请勾选要移除购物车的商品')
      }
      this.$confirm('此操作将移除勾选的购物车的商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post(deleteCartGoodsUrl, httpObj)
        if (res.code !== 200) {
          this.$message.error('删除商品失败' + res.msg)
          return false
        }
        this.$message.success('删除成功')
        this.initData()
        await this.$store.dispatch('changeCartNumActions')
      }).catch((e) => {
        console.log(e)
        this.$message.info('已取消删除')
      })
    },
    // 跳转到编辑订单页面
    toEditOrder () {
      if (this.selectGoodsList.length <= 0) {
        return this.$message.error('请选择商品')
      }
      this.$store.commit('changeSelectCartGoodsInfoMutations', this.selectGoodsList)
      this.$router.push({ path: '/editOrderInfo', query: { type: 'cart' } })
    }
  },
  computed: {
    cartTotalPrice () {
      let sum = 0
      this.selectGoodsList.forEach(item => {
        sum += item.price * item.amount
      })
      return sum.toFixed(2)
    }
  }
}
</script>

<style lang="less" scoped>
  .amount-warp {
    display: flex;

    input {
      width: 31px;
      height: 31px;
      line-height: 30px;
      padding-left: 10px;
      outline: none;
    }

    .quick-button {
      i {
        display: block;
        width: 30px;
        height: 16px;
        border-left: none;
        text-align: center;
        line-height: 16px;
        cursor: pointer;
      }

      .el-icon-arrow-up {
        border-bottom: none;
      }

      .el-icon-arrow-down {
      }
    }

    .quick-button i:hover {
      color: #EF001C;
    }
  }

  .cart-option {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #999999;

    .delete {
      margin-left: 20px;
      cursor: pointer;
    }

    .common {
      margin-right: 30px;

      .total-num {
        margin: 0 5px;
      }

      .total-price {
        font-size: 24px;
        font-weight: 700;
        vertical-align: bottom;
      }
    }
  }
</style>
